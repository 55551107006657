<template>
    <div class="con-wrap">
        <CarrotTitle title="폐기요청 리스트">
            - 폐기대기 물품을 확인할 수 있는 곳입니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                
                <table class="table-col">
                    <colgroup>
                        <col width="80">
                        <col width="120">
                        <col width="140">
                        <col width="180">
                        <col width="180">
                        <col width="100">
                        <col width="100">
                        <col width="*">
                        <col width="80">
                        <col width="80">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>자산번호</th>
                            <th>분류</th>
                            <th>모델명</th>
                            <th>시리얼</th>
                            <th>구입일</th>
                            <th>지급일</th>
                            <th>사용자</th>
                            <th>용도</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in assets.list" :key="i">
                            <td>{{ irow.num }}</td>
                            <td @click="assets.showDetail(irow.idx)"><span class="btn-view">{{ irow.code }}</span></td>
                            <td>{{ irow.cat2 }}</td>
                            <td>{{ irow.model }}</td>
                            <td>{{ irow.serialno }}</td>
                            <td>{{ irow.buy_date }}</td>
                            <td>{{ irow.gifted_date }}</td>
                            <td>{{ irow.idx_hq>0?irow.ename+'('+irow.kname+')':'-' }}</td>
                            <td>{{ irow.usage }}</td>
                            <td>
                              <p v-if="irow.state=='폐기요청'" >
                                <a class="txt-red pointer" @click="assets.setDisposal(irow.idx)">{{ irow.state }}</a>
                              </p>
                              <p v-if="irow.state=='폐기완료'">{{ irow.state }}</p>
                            </td>
                        </tr>
                        <tr v-if="assets.total==0">
                            <td colspan="11">검색 결과가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="assets.total" :list_per_page="assets.rows" v-model="assets.page" @change="assets.doSearch"></CarrotPaging>

            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import { useStore } from 'vuex';

export default {
    layout:"myPIMS",

    components: {
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const assets = reactive({
            categories : [],

            page   : 1,
            rows   : 20,

            cat1   : "",
            cat2   : "",

            sdate  : "",
            edate  : "",
            payment_sdate  : "",
            payment_edate  : "",
            idx_office : "0",
            idx_hq     : "",

            serialno : "",
            codename : "",
            model    : "",

            usage        : "",
            state        : "",

            selected : [],

            list : [], total : 0,
            showDetail: (idx) => {
                router.push({
                    name   : 'myPIMS-AssetManagementEdit-idx',
                    params : { idx:idx, page:assets.page }
                });
            },
            setDisposal: (idx) => {
              Swal.fire({
                title : '폐기',
                text  : '폐기처리 하시겠습니까?',
                showCancelButton : true
              }).then((res) => {
                  if( res.isConfirmed == true ){
                    let params = {
                      idx   : idx
                    };
                    axios.post("/rest/mypims/delAssets", params).then((res) => {
                      if( res.data.err == 0 ){
                        toast.success("삭제하였습니다.");
                        assets.doSearch();
                      } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                      }
                    });
                  }
              });

            },
            setCat1 : () =>{
                assets.cat2 = "";
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        assets.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearch: () => {
                let params = {
                    page   : assets.page,
                    rows   : assets.rows,

                    sdate  : assets.sdate,
                    edate  : assets.edate,

                    payment_sdate  : assets.payment_sdate,
                    payment_edate  : assets.payment_edate,

                    idx_office : assets.idx_office,
                    idx_hq       : assets.idx_hq,

                    serialno : assets.serialno,
                    codename : assets.codename,
                    model    : assets.model,

                    usage : assets.usage,
                    state : "폐기"
                };

                if( typeof assets.cat1 == 'undefined' || assets.cat1 == '' ){
                    params.cat1 = '';
                    params.cat2 = '';
                } else {
                    params.cat1 = assets.cat1.name;
                    if( typeof assets.cat2 == 'undefined' || assets.cat2 == '' ){
                        params.cat2 = '';
                    } else {
                        params.cat2 = assets.cat2;
                    }
                }

                axios.get('/rest/mypims/getAssetsList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        assets.list  = res.data.list;
                        assets.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

        });

        onMounted(() => {
            // Mounted
            if( store.state.isManageDisposalAdmin == false) {
                router.go(-1);
                Swal.fire({
                    title : '폐기요청 리스트',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            assets.getCategory();
            assets.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {assets};
    }
}
</script>

<style lang="scss" scoped>
</style>